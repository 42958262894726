import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Crisan8os'
  })
};

@Injectable()
export class InjectProductData {
    constructor(private http: HttpClient) {
    }

    insertProduct(formData) {
        const URL = '/admin/admin.php';
        return this.http.post(URL, formData, httpOptions);
    }
}