import { Component } from '@angular/core';


@Component({
    selector: 'faqpage',
    templateUrl: 'faqpage.component.html'
})

export class FaqpageComponent {
    elem: any;
    constructor() {
    }

    expandThisFaqItem(e) {
        const cont = document.getElementsByClassName('faq-cont')[0];
        // this.shrinkAllFaqItems(cont);
        const parentId = e.target.parentElement.id.replace('faq-', '');
        this.elem = cont.childNodes[parentId - 1];
        const expandableNode = this.elem.getElementsByClassName('faq-text')[0];
        setTimeout(() => expandableNode.classList.toggle('expanded'), 50);
    }

    shrinkAllFaqItems(cont) {
        const expandedElems = cont.getElementsByClassName('expanded');
        for (let i = 0; i < expandedElems.length; i++) {
            expandedElems[0].classList.remove('expanded');
        }
    }

}
