import { Component, OnInit } from '@angular/core';

// Data
import { Products } from './products';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html'
})
export class ProductsComponent implements OnInit {
  products = Products;

  ngOnInit() {
  }


}
