export const texts = [
    {
        text: `
        Είμαστε εταιρεία που ασχολείται με το	εμπόριο, την συντήρηση, επανέλεγχο και
        αναγόμωση πυροσβεστήρων διαφόρων τύπων.	Τα γραφεία μας εδρεύουν στο Περιστέρι Αττικής,
        επί της Θηβών 216 σε κεντρικότατο σημείο.	Στην συγκεκριμένη εγκατάσταση πραγματοποιούνται
        συναρμολογήσεις, συντηρήσεις και αναγομώσεις	πυροσβεστήρων διαφόρων τύπων.
        `,
        state: 'active'
    },
    {
        text: `
        Διαθέτουμε πλήρη μηχανολογικό εξοπλισμό,	και προσωπικό
        το οποίο αποτελείται από	άτομα με μεγάλη εμπειρία στον χώρο της	πυροπροστασίας,
        στην υλοποίηση	συμβάσεων δημοσίου και μη, ικανά	να ανταπεξέλθουν
        σε οποιαδήποτε	απαίτηση χρονική ή ποσοτική άμεσα,
        ποιοτικά και κυρίως υπεύθυνα.
        `,
        state: 'inactive'
    },
    {
        text: `
        Ανάμεσα στους πελάτες μας συγκαταλέγονται:
        ΔΕΗ Α.Ε, Ο.Σ.Ε, Ε.Λ.Ο.Τ, HELLAS SERVICE,	ΕΦΕΤΕΙΟ ΑΘΗΝΩΝ κ.α.
        Επιπλέον, μέσα στους άμεσους	στόχους μας,
        είναι να αναπτύξουμε το πελατολόγιό	μας, εντάσσοντας και άλλους πελάτες
        ιδιωτικής	και δημόσιας αρχής.
        `,
        state: 'inactive'
    },
    {
        text: `
        Σύμφωνα με τις Υπουργικές αποφάσεις
        υπ΄αριθμ.	618/43 ΦΕΚ 52/Β 20/01/2005 και 17230 /671/ΦΕΚ	1218Β/01/09/2005
        η εταιρεία μας είναι	πιστοποιημένη αναγνωρισμένη εταιρεία,	αναγόμωσης και συντήρησης πυροσβεστήρων,
        διαθέτει πιστοποιημένο αρμόδιο άτομο
        και	πιστοποιητικό ποιότητας ISO 9001:2008.
        `,
        state: 'inactive'
    }
];
