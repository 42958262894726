import { Component } from '@angular/core';


@Component({
    selector: 'productpage',
    templateUrl: 'productpage.component.html'
})

export class ProductpageComponent {

}
