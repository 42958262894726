import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { slide, hover } from './animations';
import { Observable, interval } from 'rxjs';
import { texts } from './texts';

@Component({
    selector: 'text-slider',
    templateUrl: 'text-slider.component.html',
    animations: [
        slide, hover
    ]
})

export class TextSliderComponent implements OnInit, AfterViewInit {
    state: string;
    autoMode: boolean;
    paused: boolean;
    ready: boolean;
    textArr: any[];
    arrow: object = {
        left: { state: 'inactive' },
        right: { state: 'inactive' }
    };
    @ViewChild('window') document: ElementRef;

    constructor() {
        this.textArr = texts;
    }

    ngOnInit() {
        this.paused = false;
        this.autopilot();
    }
    ngAfterViewInit() {
    }

    moveLeft() {
        let previous = 0;
        this.textArr.forEach((element, index) => {
            if (element.state === 'active' && index > 0) {
                previous = index - 1;
            } else if (element.state === 'active' && index < 1) {
                previous = this.textArr.length - 1;
            }
            element.state = 'inactive';
        });
        this.textArr[previous].state = 'active';
    }

    moveRight() {
        let next = 0;
        this.textArr.forEach((element, index) => {
            if (element.state === 'active' && index < this.textArr.length - 1) {
                next = index + 1;
            } else if (element.state === 'active' && index === this.textArr.length - 1) {
                next = 0;
            }
            element.state = 'inactive';
        });
        this.textArr[next].state = 'active';
    }

    manualHighlight(i) {
        this.paused = true;
        this.textArr.forEach((element, index) => {
            element.state = 'inactive';
        });
        this.textArr[i].state = 'active';
    }

    autopilot() {
        const sequence = interval(8000);
        sequence
            .subscribe( () => {
                if (!this.paused) {
                    this.paused = !this.paused;
                    setTimeout(() => {
                        this.moveRight();
                        this.paused = !this.paused;
                    }, 100);
                }
            });
    }
}
