import { Component, OnInit } from '@angular/core';
import { GetYtContentService } from './../../services/getYtContent.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'media-gallerypage',
    templateUrl: 'media-gallerypage.component.html'
})

export class MediaGallerypageComponent implements OnInit {
    results: any;
    videos: any = {
        ids: [],
        thumbnails: [],
        titles: []
    };

    constructor(
        private getYtContent: GetYtContentService,
        private router: Router,
        private route: ActivatedRoute
        ) {
    }

    ngOnInit() {
        this.results = [];
        this.getYtContent.getChannelFeed().subscribe(
            res => {
                const castedResponse: any = res;
                castedResponse.items.forEach( item => {
                    if (item.id.kind === 'youtube#video') {
                        this.results.push(item);
                    }});
                this.videos = {
                    ids: this.results.map(p => p.id.videoId),
                    thumbnails: this.results.map(p => p.snippet.thumbnails.high),
                    titles: this.results.map(p => p.snippet.title)
                };
            });
    }

    mediaHandler(id) {
        this.router.navigate(['media:' + id], { relativeTo: this.route });
    }
}
