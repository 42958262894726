import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'products-gallerypage',
    templateUrl: 'products-gallerypage.component.html'
})

export class ProductsGallerypageComponent implements OnInit {
    domesticatedObjects: any[];
    constructor() {
    }
    ngOnInit() {
        this.domesticatedObjects = [];
    }

    private domesticateObjects(objs) {
        const domOBJS = [];
        const data = objs.map(o => o.attachments.data);
        const media = data.map(a => a.map(d => d.media));
        const descriptions = this.flattenArray(data.map(a => a.map(d => d.description)));
        const titles = this.flattenArray(data.map(a => a.map(d => d.title)));
        const images = media.map(m => m.map(i => i.image));
        const flattenedImages = this.flattenArray(images);
        objs.forEach((index) => {
            domOBJS.push({
                id: objs[index].id || '',
                active: 'inactive',
                title: titles[index] || '',
                message: objs[index].message || '',
                src: flattenedImages[index].src,
                description: descriptions[index] || '',
                width: flattenedImages[index].width || '',
                height: flattenedImages[index].height || '',
                link: objs[index].link || '',
                source: objs[index].source || '',
            });
        });
        this.removeDuplicates(domOBJS);
        return domOBJS;
    }

    private flattenArray(obj) {
        const imgs = [];
        obj.forEach(e => e.forEach(i => imgs.push(i)));
        return imgs;
    }

    private removeDuplicates(arr) {
        for (let i = 0; i <= arr.length; i++) {
            for (let j = i; j <= arr.length; j++) {
                if (i !== j && arr[i].source === arr[j].source) {
                    arr.splice(i, 1);
                    return true;
                }
            }
        }
        return false;
    }
}
