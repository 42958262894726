import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';


@Component({
    selector: 'mediabox',
    templateUrl: './mediabox.component.html'
})


export class MediaboxComponent implements OnInit {
    isMediaboxOpen: boolean;
    videoUrl: any;
    constructor(
        private location: Location,
        private route: ActivatedRoute
        ) {
    }

    ngOnInit() {
        this.isMediaboxOpen = true;
        document.body.classList.add('stop-scroll');
        this.route.paramMap.subscribe((url: ParamMap) => {
            const videoId = url['params'].id.split(':')[1] || '';
            this.videoUrl = `https://www.youtube.com/embed/${videoId}`;
        });
    }
    goToPreviousUrl() {
        this.location.back();
    }
    closeMediabox() {
        this.isMediaboxOpen = false;
        document.body.classList.remove('stop-scroll');
        this.goToPreviousUrl();
    }
}
