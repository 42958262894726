import { Injectable } from '@angular/core';

@Injectable()
export class SliderHelpers {
    getActiveSlideIndex(array) {
        return array.findIndex(slide => slide.state === 'active');
    }
    makeAllSlidesInactive(array) {
        return array.forEach(image => image.state = 'inactive');
    }
}


