export const SliderSources = {
    images: [
        { url: 'assets/images/slider_images/compressed/7.jpg', state: 'active' },
        { url: 'assets/images/slider_images/compressed/2.jpg', state: 'inactive' },
        { url: 'assets/images/slider_images/compressed/3.jpg', state: 'inactive' },
        { url: 'assets/images/slider_images/compressed/8.jpg', state: 'inactive' },
        { url: 'assets/images/slider_images/compressed/5.jpg', state: 'inactive' },
        { url: 'assets/images/slider_images/compressed/6.jpg', state: 'inactive' },
        { url: 'assets/images/slider_images/compressed/9.jpg', state: 'inactive' },
        { url: 'assets/images/slider_images/compressed/1.jpg', state: 'inactive' }
    ],
    textTags: {
        state: 'inactive',
        tags: [
            { text: 'Άδειες λειτουργίας καταστημάτων'},
            { text: 'Προμήθεια - αναγομώσεις - εγκαταστάσεις' },
            { text: 'Mελέτες πυρασφάλειας' },
            { text: 'Πιστοποιήσεις υδροδοτικού δικτύου, πυρασφάλειας' }
        ]
    }
};

export const Controls = {
    arrows : {
        left: { state: 'inactive' },
        right: { state: 'inactive' }
    }
};
