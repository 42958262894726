import { Component, OnInit } from '@angular/core';
import { ProductCategories } from './select-data';
import { FormBuilder } from '@angular/forms';
import { InjectProductData } from './../../services/InjectProductData.service';

@Component({
    selector: 'admin-page',
    providers: [InjectProductData],
    templateUrl: 'admin-page.component.html',
})

export class AdminPageComponent implements OnInit {
    categories: any;
    categoryIndex: number;
    subCategories: any;
    checkoutForm: any;

    constructor(
        private formBuilder: FormBuilder,
        public post: InjectProductData
    ) {
        this.checkoutForm = this.formBuilder.group({
            category: '',
            sub_category: '',
            title: '',
            description: '',
            photo: ''
          });
    }

    ngOnInit() {
        this.categories = ProductCategories;
    }

    categoryHandler(event) {
        this.categoryIndex = event.target.value;
        this.subCategories = this.categories[this.categoryIndex].sub_categories;
    }

    subCategoryHandler(event) {
        const selectedSubCategory = event.target.value;
        console.log(selectedSubCategory);
    }

    onSubmit(customerData) {
        // Process checkout data here
        // this.checkoutForm.reset();
        this.checkoutForm.submit()
        console.warn('Your order has been submitted', customerData);
        this.post.insertProduct(customerData).subscribe(res => console.log(res));
      }
}
