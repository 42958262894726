import { Component, OnInit, AfterViewInit, OnChanges, OnDestroy, Input, Inject, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';

// Data
import { Products } from '../products';

@Component({
    selector: 'prod-details',
    templateUrl: 'product-details.component.html',
})

export class ProductDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('overlay') overlay;
    products = Products;
    productDetails: any;
    id: string;


    constructor(
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private location: Location,
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    ngOnInit() {
        this.route.paramMap.subscribe((urlParams: ParamMap) => {
            this.id = urlParams['params']['id'] || '';
            this.fetchProductData(this.id);
            this.blockScroll();
        });
    }

    ngOnChanges() {
    }

    ngOnDestroy() {
        this.allowScroll();
    }
    goToPreviousUrl() {
        this.location.back();
    }
    private blockScroll() {
        this.renderer.setStyle(this.overlay.nativeElement, 'height', window.innerHeight + 'px');
        this.document.body.parentElement.style.overflow = 'hidden';
        this.document.body.parentElement.scrollTop = 0;
    }

    private allowScroll() {
        this.document.body.parentElement.style.overflow = 'auto';
    }

    private fetchProductData(id) {
        const guides = id.split('.');
        this.productDetails = this.products.categories[guides[0]].sub_categories[guides[1]].products[guides[2]];
    }


}
