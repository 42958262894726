
import { trigger, state, style, animate, transition } from '@angular/animations';

export let slide = trigger('slide', [
    state('*', style({left: '0px', opacity: 0, display: 'none'})),
    state('active', style({ left: '0px', opacity: 1, display: 'flex' })),
    state('inactive', style({ left: '0px', opacity: 0, display: 'none'})),
    transition('active => inactive', animate('150ms ease-in-out')),
    transition('inactive => active', animate('100ms ease-in')),
    transition('* <=> void', animate('150ms ease-in-out')),
]);

export let hover = trigger('hover', [
    state('active', style({ opacity: 1 })),
    state('inactive', style({ opacity: 0.5 })),

    transition('active <=> inactive', animate('300ms ease-in'))
]);
