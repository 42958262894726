// MODULES
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// COMPONENTS - VIEWS
import { AppComponent } from './app.component';
import { HomepageComponent } from './containers/home-page/homepage.component';
import { ProductpageComponent } from './containers/products-page/productpage.component';
import { FaqpageComponent } from './containers/faq-page/faqpage.component';
import { ProductsGallerypageComponent } from './containers/products-gallery-page/products-gallerypage.component';
import { MediaGallerypageComponent } from './containers/media-gallery-page/media-gallerypage.component';
import { ContactpageComponent } from './containers/contact-page/contactpage.component';
import { PagenotfoundComponent } from './containers/pagenotfound.component';
import { AdminPageComponent } from './containers/admin-page/admin-page.component';
// COMPONENTS - COMPONENTS
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { HeaderComponent } from './components/header/header.component';
import { SliderComponent } from './components/slider/slider.component';
import { GalleryComponent } from './components/products-gallery/products-gallery.component';
import { TextSliderComponent } from './components/text-slider/text-slider.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductDetailsComponent } from './components/products/product-detail/product-details.component';
import { MediaboxComponent } from './components/mediabox/mediabox.component';
import { FooterComponent } from './components/footer/footer.component';
// SERVICES
import { GetYtContentService } from './services/getYtContent.service';
// PIPES
import { SafePipe } from './pipes/SafePipe';
// DIRECTIVES
import { LoadedDirective } from './directives/loaded.directive';
// ROUTES
const appRoutes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'products', component: ProductpageComponent, children: [
      { path: 'product/:id', component: ProductDetailsComponent }
    ]
  },
  {
    path: 'gallery', component: MediaGallerypageComponent, children: [
      { path: ':id', component: MediaboxComponent }
    ]
  },
  { path: 'contact', component: ContactpageComponent },
  { path: 'faq', component: FaqpageComponent },
  { path: 'admin', component: AdminPageComponent },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  declarations: [
    AppComponent, HomepageComponent, FaqpageComponent, ProductsGallerypageComponent,
    ContactpageComponent, ProductpageComponent, MediaGallerypageComponent,
    PagenotfoundComponent, HeaderComponent, CookiePolicyComponent,
    SliderComponent, TextSliderComponent, ProductsComponent, AdminPageComponent,
    ProductDetailsComponent, MediaboxComponent, FooterComponent, GalleryComponent,
    SafePipe, LoadedDirective
  ],
  imports: [
    HttpClientModule, BrowserModule, BrowserAnimationsModule, AgmCoreModule.forRoot({
      apiKey: 'AIzaSyATaGDk2RWQk_J0vsP0oucWwS7UO-Cr33w'
    }),
    FormsModule, ReactiveFormsModule, 
    RouterModule.forRoot(
      appRoutes, { useHash: false }
      // { enableTracing: false } // <-- debugging purposes only
    )
  ],
  providers: [GetYtContentService],
  bootstrap: [AppComponent]
})

export class AppModule {
}
