import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { slide, hover, fadeIn } from '../../animations/animations';
import { SliderSources, Controls } from './slider.data';
import { SliderHelpers } from '../../services/slider.helpers';


@Component({
    selector: 'slider',
    providers: [SliderHelpers],
    templateUrl: 'slider.component.html',
    animations: [
        slide, hover, fadeIn
    ]
})


export class SliderComponent implements OnInit, AfterViewInit, OnDestroy {
    state: string;
    sliderPaused = false;
    manual = false;
    ready: boolean;
    proportionalHeight: number;
    arrows: any;
    sources: any;
    activeSlideIndex: number;
    autopilotTimer: any;
    img: ElementRef;
    @ViewChild('window') document: ElementRef;

    constructor(public sliderHelpers: SliderHelpers) {
    }

    ngOnInit() {
        this.sources = { images: [SliderSources.images[0]], textTags: SliderSources.textTags };
        this.arrows = Controls;
        this.sources.textTags.state = 'active';
    }

    ngAfterViewInit() {
        this.startAutoPilot();
    }

    pushRemainingSlides(index) {
        if (index === 0) {
            this.sources.images.push(...SliderSources.images.slice(1, SliderSources.images.length));
        } else {
            return false;
        }
    }

    animateSlider(action) {
        if (!this.manual) {
            this.manual = true;
            clearTimeout(this.autopilotTimer);
        }

        this.activeSlideIndex = this.sliderHelpers.getActiveSlideIndex(this.sources.images);
        this.sliderHelpers.makeAllSlidesInactive(this.sources.images);
        switch (action) {
            case 'right':
                this.moveImageRight();
                break;
            case 'left':
                this.moveImageLeft();
                break;
            default:
                this.moveImageRight();
        }
    }

    moveImageRight() {
        if (this.activeSlideIndex === this.sources.images.length - 1) {
            this.activeSlideIndex = 0;
            this.sources.images[this.activeSlideIndex].state = 'active';
        } else {
            this.sources.images[this.activeSlideIndex + 1].state = 'active';
        }

    }
    moveImageLeft() {
        if (this.activeSlideIndex === 0) {
            this.activeSlideIndex = this.sources.images.length;
        }
        this.sources.images[this.activeSlideIndex - 1].state = 'active';
    }

    startAutoPilot() {
        const autopilotDelay = 10000;
        this.autopilotTimer = setTimeout(() => {
            if (!this.sliderPaused) {
                this.activeSlideIndex = this.sliderHelpers.getActiveSlideIndex(this.sources.images);
                this.sliderHelpers.makeAllSlidesInactive(this.sources.images);
                this.moveImageRight();
                clearTimeout(this.autopilotTimer);
                this.startAutoPilot();
            }
            return;
        }, autopilotDelay);
    }

    togglePause() {
        this.sliderPaused = !this.sliderPaused;
        if (!this.sliderPaused) {
            this.startAutoPilot();
        }
    }

    ngOnDestroy() {
        this.sliderPaused = true;
    }
}

