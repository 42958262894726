import { Component } from '@angular/core';

@Component({
    selector: 'contactpage',
    templateUrl: 'contactpage.component.html',
    styles: [`
        agm-map {
            position: absolute;
            background-color: transparent;
            min-height: 100%;
            width: 100%;
          }
    `]
})
export class ContactpageComponent {
    pyrofasmaUrl = 'https://goo.gl/maps/oRJziFcYYwj';
    lat = 38.016778;
    lng = 23.691677;
    zoomLevel = 16;
    markerImage = 'assets/images/icons/map/logo_pin.png'; // CUSTOM PIN IMAGE
    pyrofasmaMapStyles = [
        {
            elementType: 'geometry',
            stylers: [{
                color: '#2E2E2E'
                // DARKISH GREY
            }]
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{
                color: '#46551e'
            }] // MILITARY GREEN
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{
                color: '#17263c'
            }]
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [{
                color: '#ffff'
            }] // WHITE
        },
        {
            elementType: 'labels.text.fill',
            stylers: [{
                color: '#cbcbcb'
            }] // LIGHT GREY
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{
                color: '#723434'
            }] // PYROFASMA RED
        },
        {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [{
                color: '#5f5f5f'
            }] // LIGHT GREY
        },
        {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{
                color: '#2f3948'
            }]
        },
        {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{
                color: '#ffffff'
            }] // WHITE
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{
                color: '#c3101c'
            }] // FIRE RED
        },
        {
            featureType: 'poi.business',
            elementType: 'labels.text.stroke',
            stylers: [{
                color: '#ffff'
            }] // BLUE ELECTRIC
        }
    ];
    zoomOnScroll = false;

    clickedMarker() {
        window.open(this.pyrofasmaUrl, '_blank');
    }

}
