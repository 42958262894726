export const ProductCategories = [
    {
        id: '0',
        title: `Πυροσβεστήρες`,
        sub_categories: [
            {
                id: '0.1',
                title: `Ξηράς Κόνεως`,
                active: 1
            },
            {
                id: '0.2',
                title: `Αφρού`,
                active: 1
            },
            {
                id: '0.3',
                title: `Τύπου F`,
                active: 1
            },
            {
                id: '0.4',
                title: `CO2`,
                active: 1
            },
            {
                id: '0.5',
                title: `Οροφής`,
                active: 1
            },
            {
                id: '0.6',
                title: `Ειδικού τύπου`,
                active: 1
            },
            {
                id: '0.7',
                title: `Τ.Εφαρμογής`,
                active: 1
            }
        ],
        active: 1,
        color: 'soft'
    },
    {
        id: '1',
        title: `Πυρ.Φωλιές & Εξοπλισμός`,
        sub_categories: [
            {
                id: '1.0',
                title: `Πυροσβεστικές Φωλιές`,
                active: 1
            },
            {
                id: '1.1',
                title: `Λάστιχα`,
                active: 1
            },
            {
                id: '1.2',
                title: `Μάνικες`,
                active: 1
            },
            {
                id: '1.3',
                title: `Ρακόρ`,
                active: 1
            },
            {
                id: '1.4',
                title: `Αυλοί`,
                active: 1
            },
            {
                id: '1.5',
                title: `Κρούνοι`,
                active: 1
            }
        ],
        active: 1,
        color: 'dark'
    },
    {
        id: '2',
        title: `Εξαρτήματα Πυροσβεστήρων`,
        sub_categories: [
            {
                id: '2.0',
                title: `Σωλήνες`,
                active: 1
            },
            {
                id: '2.1',
                title: `Σπρίνγκλερ`,
                active: 1
            },
            {
                id: '2.2',
                title: `Βάσεις`,
                active: 1
            },
            {
                id: '2.3',
                title: `Θήκες`,
                active: 1
            },
            {
                id: '2.4',
                title: `Καλώδια`,
                active: 1
            }
        ],
        active: 0,
        color: 'darker'
    },
    {
        id: '3',
        title: `Συστήματα Πυρανίχνευσης`,
        sub_categories: [
            {
                id: '3.0',
                title: `Σετ Πυρανίχνευσης`,
                active: 1
            },
            {
                id: '3.1',
                title: `Σειρήνες - Φάροι`,
                active: 1
            },
            {
                id: '3.2',
                title: `Συμβατικοί Ανιχνευτές`,
                active: 1
            },
            {
                id: '3.3',
                title: `Συμβατικά Μπουτόν`,
                active: 1
            }
        ],
        active: 1,
        color: 'dank'
    }
]