import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { hover } from '../../animations/animations';
import { Router, ActivatedRoute } from '@angular/router';

// Animations
import { scale } from '../../animations/animations';

@Component({
    selector: 'products-gallery',
    templateUrl: './products-gallery.component.html',
    animations: [
        scale, hover
    ]
})

export class GalleryComponent implements OnInit, AfterViewInit {
    @Input() products: any;
    categories: any;
    active = 'inactive';
    isProductGallery = false;
    constructor(
        private router: Router,
        private route: ActivatedRoute) {
    }
    ngOnInit() {
        if (this.route.snapshot.url[0].path === 'products') {
            this.isProductGallery = true;
            this.categories = this.products.categories;
        }
    }

    ngAfterViewInit() {
    }

    private toggleFilter(id: string) {
        const activeCategories = this.categories.reduce(this.boolToNum, 0);
        this.categories[id].active = (this.categories[id].active && activeCategories > 1) ? false : true;
    }

    private boolToNum(n, productCategory) {
        return n + (productCategory.active ? 1 : 0);
    }

    private productDetailsHandler(el: any) {
        if (el.hasOwnProperty('description')) {
            this.router.navigate(['product', el.id], { relativeTo: this.route });
        } else {
            this.router.navigate(['post', el.id], { relativeTo: this.route });
        }
    }

    toggleActive(index) {
        this.products[index].active = this.products[index].active === 'active' ? 'inactive' : 'active';
    }
}


