import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cookie-policy',
  templateUrl: './cookie-policy.component.html'
})

export class CookiePolicyComponent implements OnInit {
  cookiePolicyAccepted: Boolean;

  ngOnInit() {
    this.checkIfUserAcceptedCookie();
  }

  checkIfUserAcceptedCookie() {
    const cookie = localStorage.getItem('pyrofasma-cookie-policy-accepted');
    return this.cookiePolicyAccepted = cookie === '1' || cookie !== null ? true : false;
  }

  acceptPolicy() {
    localStorage.setItem('pyrofasma-cookie-policy-accepted', '1');
    this.cookiePolicyAccepted = true;
  }

  rejectPolicy() {
    localStorage.setItem('pyrofasma-cookie-policy-accepted', '-1');
    this.cookiePolicyAccepted = false;
  }

}
