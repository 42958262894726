import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

// declare google analytics
declare const ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements AfterViewInit, OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private router: Router) {

  }

  ngOnInit () {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngAfterViewInit() {
      this.router.events.subscribe(event => {
          // I check for isPlatformBrowser here because I'm using Angular Universal, you may not need it
          if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
          }
        });
      }
  }