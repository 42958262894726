export const Products = {
    categories: [
        {
            id: '0',
            title: `Πυροσβεστήρες`,
            sub_categories: [
                {
                    id: '0.1',
                    title: `Ξηράς Κόνεως`,
                    products: [
                        {
                            id: `0.0.0`, title: `Πυροσβεστήρας 1Kg Ξηράς Κόνεως με Μεταλλική Βάση`,
                            description: `<strong>Περιγραφή:</strong> Πυροσβεστήρας 1Kg Ξηράς Κόνεως ABC 40% με Μονόραφο Δοχείο με κλείστρο
                                    με Βαλβίδα Ασφαλείας Μανομέτρου. ΣΥΜΠΕΡΙΛΑΜΒΑΝΕΤΑΙ ΜΕΤΑΛΛΙΚΗ ΒΑΣΗ.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/1.png`,
                        },
                        {
                            id: `0.0.1`, title: `Πυροσβεστήρας 2Kg Ξηράς Κόνεως EXCLUSIVE με Μεταλλική Βάση`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Aνταλλακτικό φιαλίδιο για τον πυροσβεστήρα Ξηράς Κόνεως <br>
                                        ΜΒΚ09 - 060PA - PIL <br>Σπείρωμα: Μ18x1,5 <br>Mήκος φιαλιδίου: 275mm<br>Χωρητικότητα 120gr.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/2.png`,
                        },
                        {
                            id: `0.0.2`, title: `Πυροσβεστήρας 3Kg Ξηράς Κόνεως INOX`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστήρας 3Kg Ξηράς Κόνεως ABC
                                            40% INOX Ξηράς Κόνεως με Μονόραφο Δοχείο.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/3.png`,
                        },
                        {
                            id: `0.0.3`, title: `Πυροσβεστήρας 6Kg Ξηράς Κόνεως τύπου D`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστήρας 6Kg Ξηράς Κόνεως, με Δοχείο Μονόραφο και Κλείστρο
                                        με Βαλβίδα Ασφαλείας Μανομέτρου.
                                        Κατάλληλος για φωτιές τύπου D (Νάτριο, Κάλιο - όχι κατάλληλο για Λίθιο).`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/4.png`,
                        },
                        {
                            id: `0.0.4`, title: `Πυροσβεστήρας 12Kg Ξηράς Κόνεως BALKAN`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστήρας 12Kg Ξηράς Κόνεως ABC 40% με Μονόραφο Δοχείο
                                        και Κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου. BALKAN.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/5.png`,
                        },
                        {
                            id: `0.0.5`, title: `Πυροσβεστήρας 9Kg Ξηράς Κόνεως`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστήρας 9Kg Ξηράς Κόνεως ABC 40% με Μονόραφο Δοχείο
                                        και Κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/6.png`
                        },
                        {
                            id: `0.0.6`, title: `Τροχήλατος Πυροσβεστήρας 25Kg Ξηράς Κόνεως`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Τροχήλατος Πυροσβεστήρας, 25Kg Ξηράς Κόνεως ABC 40%,
                                        με Λάστιχο Εκτόξευσης Υλικού 5 μέτρων και με κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου.
                                        ΟΔΗΓΙΕΣ ΧΡΗΣΕΩΣ & ΣΥΝΤΗΡΗΣΗΣ ΣΕ ΜΕΤΑΞΟΤΥΠΙΑ. Προσθήκη 3ου βοηθητικού τροχού
                                        στο μπροστινό μέρος του τροχήλατου πυροσβεστήρα για την ευκολότερη χρήση μετακίνηση του.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/7.png`,
                        },
                        {
                            id: `0.0.7`, title: `Τροχήλατος Πυροσβεστήρας 50Kg Ξηράς Κόνεως`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Τροχήλατος Πυροσβεστήρας, 50Kg Ξηράς Κόνεως ABC 40%,
                                        με Λάστιχο Εκτόξευσης Υλικού 5 μέτρων και με κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου.
                                        ΟΔΗΓΙΕΣ ΧΡΗΣΕΩΣ & ΣΥΝΤΗΡΗΣΗΣ ΣΕ ΜΕΤΑΞΟΤΥΠΙΑ. Προσθήκη 3ου βοηθητικού τροχού στο μπροστινό μέρος
                                        του τροχήλατου πυροσβεστήρα για την ευκολότερη χρήση μετακίνηση του.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/8.png`,
                        },
                        {
                            id: `0.0.8`, title: `Aνταλλακτικό φιαλίδιο για τον πυρ/στήρα Ξηράς Κόνεως`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Aνταλλακτικό φιαλίδιο για τον πυροσβεστήρα Ξηράς Κόνεως <br>
                                        ΜΒΚ09 - 060PA - PIL <br>Σπείρωμα: Μ18x1,5 <br>Mήκος φιαλιδίου: 275mm<br>Χωρητικότητα 120gr.`,
                            photo: `assets/images/products/1Pyrosvestires/1skonis/thumbnails_big/9.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '0.2',
                    title: `Αφρού`,
                    products: [
                        {
                            id: `0.1.0`, title: `Πυροσβεστήρας 1Lt Αφρού με Μεταλλική Βάση`,
                            description: `<strong>Περιγραφή:</strong> <br><br> Πυροσβεστήρας 1Lt Αφρού AFFF (6%) με Δοχείο Μονόραφο
                                    με Πλαστική Εσωτερική Επικάλυψη με Κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου.<br<br
                                    ΣΥΜΠΕΡΙΛΑΜΒΑΝΕΤΑΙ ΜΕΤΑΛΛΙΚΗ ΒΑΣΗ.`,
                            photo: `assets/images/products/1Pyrosvestires/2afrou/thumbnails_big/1.png`,
                        },
                        {
                            id: `0.1.1`, title: `Πυροσβεστήρας 3Lt Αφρού με Μεταλλική Βάση`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Aνταλλακτικό φιαλίδιο για τον πυροσβεστήρα Ξηράς Κόνεως <br>
                                        ΜΒΚ09 - 060PA - PIL <br>Σπείρωμα: Μ18x1,5 <br>Mήκος φιαλιδίου: 275mm<br>Χωρητικότητα 120gr.`,
                            photo: `assets/images/products/1Pyrosvestires/2afrou/thumbnails_big/2.png`,
                        },
                        {
                            id: `0.1.2`, title: `Πυροσβεστήρας 6Lt Αφρού`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστήρας 6Lt Αφρού AFFF (6%)
                                    με Δοχείο Μονόραφο με Πλαστική Εσωτερική Επικάλυψη και Κλείστρο με Βαλβίδα Ασφαλείας Mανομέτρου.`,
                            photo: `assets/images/products/1Pyrosvestires/2afrou/thumbnails_big/3.png`,
                        },
                        {
                            id: `0.1.3`, title: `Πυροσβεστήρας 9Lt Αφρού`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστήρας 9Lt Αφρού AFFF (6%)
                                    με Δοχείο Μονόραφο ΜΕ ΠΛΑΣΤΙΚΗ ΕΣΩΤΕΡΙΚΗ ΕΠΙΚΑΛΥΨΗ και με Κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου.`,
                            photo: `assets/images/products/1Pyrosvestires/2afrou/thumbnails_big/4.png`,
                        },
                    ],
                    active: 1
                },
                {
                    id: '0.3',
                    title: `Τύπου F`,
                    products: [
                    ],
                    active: 1
                },
                {
                    id: '0.4',
                    title: `CO2`,
                    products: [
                    ],
                    active: 1
                },
                {
                    id: '0.5',
                    title: `Οροφής`,
                    products: [
                    ],
                    active: 1
                },
                {
                    id: '0.6',
                    title: `Ειδικού τύπου`,
                    products: [
                        {
                            id: `0.5.0`, title: `Αυτόματος Πυροσβεστήρας – Μινιατούρα (AMFE)`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                            ΕΝΣΩΜΑΤΩΜΕΝΗ ΑΣΦΑΛΕΙΑ
                            Ο αυτόματος πυροσβεστήρας – μινιατούρα (AMFE) <br>
                            προστατεύει αξιόπιστα τις συσκευές και τον
                            βιομηχανικό εξοπλισμό, οικιακές συσκευές και καταναλωτικά ηλεκτρονικά προϊόντα, όπως ερμάρια,
                            οικιακές ηλεκτρικές συσκευές, τηλεοράσεις, κ.λπ από πυρκαγιά.<br>
                             Ο AMFE ανιχνεύει και κατασβήνει
                            τη φωτιά εντός της συσκευής, αποτρέποντας έτσι την εξάπλωση της πυρκαγιάς.
                            Πλυντήρια, τηλεοράσεις ή βιομηχανικές συσκευές τροφοδοσίας`,
                            photo: `assets/images/products/1Pyrosvestires/6special/thumbnails_big/7.jpg`,
                        },
                        {
                            id: `0.5.1`, title: `Πυροσβεστήρας 2Lt ABF με Εξωτερικό Σπείρωμα Δοχείου`,
                            description: `<strong>Περιγραφή:</strong>
                            </br></br>
                            Πυροσβεστήρας 2Lt με Δοχείο Μονόραφο με ΕΣΩΤΕΡΙΚΗ ΠΛΑΣΤΙΚΗ ΕΠΙΚΑΛΥΨΗ και Εξωτερικό Σπείρωμα.
                            Κλείστρο με Βαλβίδα Ασφαλείας Μανομέτρου.</br>
                            > ΣΥΜΠΕΡΙΛΑΜΒΑΝΕΤΑΙ ΜΕΤΑΛΛΙΚΗ ΒΑΣΗ.`,
                            photo: `assets/images/products/1Pyrosvestires/6special/thumbnails_big/8.jpg`,
                        },
                        {
                            id: `0.5.2`, title: `Πυροσβεστήρας 19Lt ABF-Wet chemical Τοπ. Εφαρμ. Θέση για Πυροκρ.`,
                            description: `<strong>Περιγραφή:</strong>
                            </br></br>
                            Πυροσβεστήρας Τοπικής Εφαρμογής, ABF – “Wet Chemical” με αυτόματο πιστοποιημένο CE Κλείστρο
                            με ασφαλιστικό και με θέση για πυροκροτητή ¼” (δεν περιλαμβάνεται)
                            Χωρητικότητα δοχείου 19Lt
                            Δοχείο με πλαστική εσωτερική επικάλυψη.`,
                            photo: `assets/images/products/1Pyrosvestires/6special/thumbnails_big/9.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '0.7',
                    title: `Τ.Εφαρμογής`,
                    products: [
                    ],
                    active: 1
                }
            ],
            active: 1,
            color: 'light'
        },
        {
            id: '1',
            title: `Πυρ.Φωλιές & Εξοπλισμός`,
            sub_categories: [
                {
                    id: '1.0',
                    title: `Πυροσβεστικές Φωλιές`,
                    products: [
                        {
                            id: `1.0.0`, title: `Πυροσβεστικός Σταθμός Εργαλείων, δύο Χώρων ΙΝΟΧ 304`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστικός Σταθμός Εργαλείων, δύο Χώρων,
                                    με Διαθέσιμο Χώρο και για την Τοποθέτηση Μεγάλων Εργαλείων και
                                    Αναπνευστικής Συσκευής Διαφυγής.<br<br>ΔΙΑΣΤΑΣΕΙΣ (ύψος x πλάτος x βάθος): 700 x 630 x 180 mm.`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/1fwlies/thumbnails_big/1.png`,
                        },
                        {
                            id: `1.0.1`, title: `Ανοξείδωτη Πυροσβεστική Φωλιά με Γάντζο ΙΝΟΧ 304`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Ανοξείδωτη Πυροσβεστική Φωλιά με Γάντζο.<br>
                                    Άνοιγμα Πόρτας με την Πίεση Ανοξείδωτου Κομβίου.<br><br>
                                    ΔΙΑΣΤΑΣΕΙΣ (ύψος x πλάτος x βάθος): 500 x 470 x 140 mm.`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/1fwlies/thumbnails_big/2.png`,
                        },
                        {
                            id: `1.0.2`, title: `Ανοξείδωτη Πυροσβεστική Φωλιά με Ανοξείδωτη Ανέμη`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Ανοξείδωτη Πυροσβεστική Φωλιά με Ανοξείδωτη Aνέμη.<br>
                                    Άνοιγμα Πόρτας με την Πίεση Ανοξείδωτου Κομβίου<br><br>
                                    ΔΙΑΣΤΑΣΕΙΣ (ύψος x πλάτος x βάθος):700 x 630 x 185mm.`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/1fwlies/thumbnails_big/3.png`,
                        },
                        {
                            id: `1.0.3`, title: `Πυροσβεστική Φωλιά με Γάντζο (MBK05-FC-SMALL)`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστική Φωλιά με λάστιχο Νερού, Πλαστικό Ρυθμιζόμενο Ακροσωλήνιο,
                                    Ρακοράκι Σύνδεσης με τη Βρύση και Δύο Γαλβανιζέ Σφικτήρες.
                                    Μη Συναρμολογημένη, αλλά με όλα τα Εξαρτήματα Τοποθετημένα εντός Φωλιάς.<br><br>
                                    Hλεκτροστατική Βαφή RAL 3000 UV - ProtectionΜε Μεταξοτυπία.`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/1fwlies/thumbnails_big/4.png`,
                        },
                        {
                            id: `1.0.4`, title: `Πυροσβεστική Φωλιά με Ανέμη`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστική Φωλιά με Γάντζο, με Κόκκινο Λάστιχο Πυροσβεστική Φωλιά με Ανέμη,
                                    μεταλλική, κόκκινη. Hλεκτροστατική Βαφή RAL 3000, UV - Protection Με Μεταξοτυπία.<br><br>
                                    ΔΙΑΣΤΑΣΕΙΣ (ύψος x πλάτος x βάθος): 700 x 630 x 180 mm.`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/1fwlies/thumbnails_big/5.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '1.1',
                    title: `Λάστιχα`,
                    products: [
                        {
                            id: `1.1.0`, title: `Kόκκινο Λάστιχο Νερού 1/2`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Κόκκινο Λάστιχο Νερού 1/2`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/2lastixa/thumbnails_big/1.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '1.2',
                    title: `Μάνικες`,
                    products: [
                    ],
                    active: 1
                },
                {
                    id: '1.3',
                    title: `Ρακόρ`,
                    products: [
                    ],
                    active: 1
                },
                {
                    id: '1.4',
                    title: `Αυλοί`,
                    products: [
                    ],
                    active: 1
                },
                {
                    id: '1.5',
                    title: `Κρούνοι`,
                    products: [
                    ],
                    active: 1
                }
            ],
            active: 0,
            color: 'dark'
        },
        {
            id: '2',
            title: `Εξαρτήματα Πυροσβεστήρων`,
            sub_categories: [
                {
                    id: '2.0',
                    title: `Σωλήνες`,
                    products: [
                        {
                            id: `2.0.0`, title: `Σωλήνας Εύκαμπτος Μον. Συστήματος 21,7 - 3/4`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Πυροσβεστικός Σταθμός Εργαλείων, δύο Χώρων,
                                    με Διαθέσιμο Χώρο και για την Τοποθέτηση Μεγάλων Εργαλείων και
                                    Αναπνευστικής Συσκευής Διαφυγής.<br<br>ΔΙΑΣΤΑΣΕΙΣ (ύψος x πλάτος x βάθος): 700 x 630 x 180 mm.`,
                            photo: `assets/images/products/2Pyrosvestikes_fwlies&exoplismos/1fwlies/thumbnails_big/1.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '2.1',
                    title: `Σπρίνγκλερ`,
                    products: [
                        {
                            id: `2.1.0`, title: `Σπρίνκλερ 1/2, 68°C, Upright/Pendent`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Σπρίνκλερ 1/2, νίκελ, 68°C, Κ=80,6, Upright/Pendent.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/1.png`,
                        },
                        {
                            id: `2.1.1`, title: `Σπρίνκλερ 1/2, 141°C, Upright/Pendent`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Σπρίνκλερ 1/2, νίκελ, 141°C, Κ=80,6, Upright/Pendent.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/2.png`,
                        },
                        {
                            id: `2.1.2`, title: `Σπρίνκλερ 1/2 ανοιχτού τύπου, Upright/Pendent`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Σπρίνκλερ ανοιχτού τύπου, 1/2, Κ=22,06, Pendent.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/3.png`,
                        },
                        {
                            id: `2.1.3`, title: `Pendent Σπρίνκλερ – Γρήγορης Απόκρισης – UL`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                            Pendent Σπρίνκλερ – Γρήγορης Απόκρισης – Ορειχάλκινο ή Χρωμιωμένο – Σπείρωμα NPT1/2 ή R1/2
                            </br></br>
                            Διατίθεται στις παρακάτω θερμοκρασίες ενεργοποίησης:
                              - 57 °C ∕ 135°F </br>
                              - 68 °C ∕ 155°F </br>
                              - 79 °C ∕ 175°F </br>
                              - 93 °C ∕ 200°F </br></br>
                            Όλα Τα Σπρίνκλερ Συνοδεύονται από Ειδικό Πλαστικό το Οποίο Προστατεύει τα Γυαλάκια κατά τη Μεταφορά και Εγκατάσταση αυτών.
                            Να Αφαιρείται μετά την Εγκατάσταση.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/4.jpg`,
                        },
                        {
                            id: `2.1.4`, title: `Εντοιχιζόμενα Σπρίνκλερ – Σταθερής Απόκρισης – UL`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                            Εντοιχιζόμενα Σπρίνκλερ – Σταθερής Απόκρισης – Ορειχάλκινο ή Χρωμιωμένο – Σπείρωμα NPT1/2 ή R1/2
                            </br></br>
                            Διατίθεται στις παρακάτω θερμοκρασίες ενεργοποίησης:
                              - 57 °C ∕ 135°F </br>
                              - 68 °C ∕ 155°F </br>
                              - 79 °C ∕ 175°F </br>
                              - 93 °C ∕ 200°F </br></br>
                            Όλα Τα Σπρίνκλερ Συνοδεύονται από Ειδικό Πλαστικό το Οποίο Προστατεύει τα Γυαλάκια κατά τη Μεταφορά και Εγκατάσταση αυτών.
                            Να Αφαιρείται μετά την Εγκατάσταση.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/5.jpg`,
                        },
                        {
                            id: `2.1.5`, title: `Ειδικού τύπου σπρίνκλερ 182°C`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                            Σπρίνκλερ ειδικού τύπου 182°C Κατάλληλο για μόνιμα συστήματα F class solution – Wet Chemical.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/6.jpg`,
                        },
                        {
                            id: `2.1.6`, title: `Ειδικού τύπου σπρίνκλερ 260°C`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                            Σπρίνκλερ ειδικού τύπου 260°C Κατάλληλο για μόνιμα συστήματα F class solution – Wet Chemical.`,
                            photo: `assets/images/products/3Exartimata/2springler/thumbnails_big/6.jpg`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '2.2',
                    title: `Βάσεις`,
                    products: [
                        {
                            id: `2.2.0`, title: `Βάση Πυρ/ρα Μεταλλική 3Κg / 3Lt`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Ηλεκτροστατικής Βαφής,
                                    με Πλαστικά Προστατευτικά στα ‘Ακρα για τον μη Τραυματισμό του Δοχείου`,
                            photo: `assets/images/products/3Exartimata/3vaseis/thumbnails_big/1.png`,
                        },
                        {
                            id: `2.2.1`, title: `Βάση Πυρ/ρα Μεταλλική Επιτοίχια CO2 5Kg`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Bάση επιτοίχια φορητού πυροσβεστήρα CO2 5Kg.
                                    Συνοδεύει τον αντίστοιχο πυροσβεστήρα κατά τη διάθεσή του μέσα στο χαρτοκιβώτιο.`,
                            photo: `assets/images/products/3Exartimata/3vaseis/thumbnails_big/2.png`,
                        },
                        {
                            id: `2.2.2`, title: `Βάση Πυρ/ρα Πλαστική Φ130`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Φ130, για Χαλύβδινο και ΙΝΟΧ Πυροσβεστήρα 3Kg/3Lt.`,
                            photo: `assets/images/products/3Exartimata/3vaseis/thumbnails_big/2.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '2.3',
                    title: `Θήκες`,
                    products: [
                        {
                            id: `2.3.0`, title: `Θήκη Πυρ/ρα Πλαστική Μεταφ.-Προστ. 2Kg/2Lt`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                                    Θήκη Πλαστική Μεταφοράς - Προστασίας για Πυροσβεστήρα 2Κg/2Lt.`,
                            photo: `assets/images/products/3Exartimata/4thikes/thumbnails_big/1.png`,
                        },
                    ],
                    active: 1
                },
                {
                    id: '2.4',
                    title: `Καλώδια`,
                    products: [
                        {
                            id: `2.4.0`, title: `Καλώδιο 68°C`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Καλώδιο γραμμικής ανίχνευσης θερμοκρασίας 68°C, UL/FM πιστοποιημένο.`,
                            photo: `assets/images/products/3Exartimata/5kalwdia/thumbnails_big/1.png`,
                        },
                        {
                            id: `2.4.1`, title: `Καλώδιο 138°C`,
                            description: `<strong>Περιγραφή:</strong> <br><br>Καλώδιο γραμμικής ανίχνευσης θερμοκρασίας 138°C UL/FM πιστοποιημένο.`,
                            photo: `assets/images/products/3Exartimata/5kalwdia/thumbnails_big/2.png`,
                        }
                    ],
                    active: 1
                }
            ],
            active: 1,
            color: 'darker'
        },
        {
            id: '3',
            title: `Συστήματα Πυρανίχνευσης`,
            sub_categories: [
                {
                    id: '3.0',
                    title: `Σετ Πυρανίχνευσης`,
                    products: [
                        {
                            id: `3.0.0`, title: `Σετ Πυρανίχνευσης`,
                            description: `no data...`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/1set_pyranixneyshs/thumbnails_big/1.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '3.1',
                    title: `Σειρήνες - Φάροι`,
                    products: [
                        {
                            id: `3.1.0`, title: `Σειρήνα BS-530`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                                Τα μοντέλα αυτά αποτελούν μέσα ένδειξης του πίνακα  πυρανίχνευσης
                                που δίνουν ηχητική σήμανση με την σειρήνα και οπτική σήμανση με το φάρο που περιέχουν(μόνο η BS-531).
                                Ο οξύς ήχος και το φωτεινό σήμα που παράγoυν(μόνο η BS-531) καλύπτουν χώρο αρκετών τετραγωνικών μέτρων.
                                Μπορούν  να συνεργαστούν με  οποιοδήποτε πίνακα πυρανίχνευσης
                                (BSR-1116, BS-116, BS-632, BS-633, BS-634, BS-636).`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/2seirines/thumbnails_big/1.png`,
                        },
                        {
                            id: `3.1.0`, title: `Σετ Πυρανίχνευσης`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                                    Περιέχει όλα τα απαραίτητα εξαρτήματα για να δημιουργηθεί μια εγκατάσταση
                                    ανίχνευσης φωτιάς σε μικρούς χώρους.<br><br>Το σετ BS-619 περιλαμβάνει :<br><br>
                                    1 συμβατικό πίνακα πυρανίχνευσης 2 ζωνών BS-632, 1 μπαταρία A-986 για τον πίνακα,
                                    Έναν ανιχνευτή καπνού BS-655, 1 θερμοδιαφορικό ανιχνευτή BS-660,
                                    1 μπουτόν πυρασφαλείας BS-536 και 1 σειρήνα με φάρο BS-531`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/1set_pyranixneyshs/thumbnails_big/1.png`,
                        },
                        {
                            id: `3.1.1`, title: `Σετ Πυρανίχνευσης`,
                            description: `<strong>Περιγραφή:</strong><br><br>
                                    Τα μοντέλα αυτά αποτελούν μέσα ένδειξης του πίνακα
                                    πυρανίχνευσης που δίνουν ηχητική σήμανση με την σειρήνα
                                    και οπτική σήμανση με το φάρο που περιέχουν (μόνο η BS-531).
                                    Ο οξύς  ήχος και το φωτεινό σήμα που παράγoυν (μόνο η BS-531)
                                    καλύπτουν  χώρο  αρκετών τετραγωνικών μέτρων.Μπορούν  να συνεργαστούν
                                    με  οποιοδήποτε πίνακα πυρανίχνευσης (BSR-1116, BS-116, BS-632, BS-633, BS-634, BS-636).`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/1set_pyranixneyshs/thumbnails_big/2.png`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '3.2',
                    title: `Συμβατικοί Ανιχνευτές`,
                    products: [
                        {
                            id: `3.2.0`, title: `Ανιχνευτής Μονοξειδίου του Άνθρακα`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                                Ανιχνευτής Μονοξειδίου του Άνθρακα
                                Διαστάσεις: 102mm x 35mm.`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/3symbatikoi_anixneytes/thumbnails_big/3.png`,
                        },
                        {
                            id: `3.2.1`, title: `Αυτόνομος ανιχνευτής γκαζιού Συμβατικός`,
                            description: `<strong>Περιγραφή:</strong> <br><br>
                                Αυτόνομος ανιχνευτής γκαζιού με μπαταρία.`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/3symbatikoi_anixneytes/thumbnails_big/4.jpg`,
                        }
                    ],
                    active: 1
                },
                {
                    id: '3.3',
                    title: `Συμβατικά Μπουτόν`,
                    products: [
                        {
                            id: `3.3.0`, title: `Σετ Πυρανίχνευσης`,
                            description: `no data...`,
                            photo: `assets/images/products/4systimata_pyranixneyshs/1set_pyranixneyshs/thumbnails_big/1.png`,
                        }
                    ],
                    active: 1
                }
            ],
            active: 1,
            color: 'dank'
        },
        {
            id: '4',
            title: `Gadjets`,
            sub_categories: [
                {
                    id: '4.0',
                    title: `Gadjets`,
                    products: [
                        {
                            id: `4.0.0`, title: `Πυροσβεστήρας Tύπου Φλασκί 1Κg Ξηράς Σκόνης`,
                            description: `Πυροσβεστήρας Tύπου Φλασκί 1Κg Ξηράς Σκόνης ABC 85% (συνοδεύεται από ειδική πλαστική βάση).
                            Κατάλληλος για χρήση σε αυτοκίνητα και σκάφη. Ιδανικός για δώρο.`,
                            photo: `assets/images/products/7Gadjets/1.jpg`,
                        },
                        {
                            id: `4.0.1`, title: `Έξυπνος Πυροσβεστήρας Λουλούδι`,
                            description: `Ο Έξυπνος Πυροσβεστήρας Λουλούδι Χρησιμοποιείται για Κατάσβεση Πυρκαγιάς Αρχικού Σταδίο Προερχομένης από Μαγειρικά Λίπη.
                            Κατά την Τοποθέτηση μπορεί επίσης να Χρησιμοποιηθεί σαν Διακοσμητικό Κουζίνας πάνω σε Μεταλλική Επιφάνεια Ψυγείου (διαθέτει μαγνήτη) ή μέσα σε Βάζο για Λουλούδια.
                            Κατάλληλος για Επαγγελματική και Οικιακή Χρήση. Ιδανικός για Δώρο.Βεβαίωση δοκιμών Κατασβεστικής ικανότητας από ΕΒΕΤΑΜ
                            ΧΡΟΝΟΣ ΖΩΗΣ 5 ΕΤΗ`,
                            photo: `assets/images/products/7Gadjets/2.jpg`,
                        },
                        {
                            id: `4.0.2`, title: `Φορητός Πυροσβεστήρας Aerosol`,
                            description: `Ο Μικρότερος Φορητός Πυροσβεστήρας Aerosol
                                • Νέας Γενιάς – Έξυπνο – Καθαρό – Οικονομικό Κατασβεστικό Υλικό.
                                • Μικρό – Εύχρηστο Μέγεθος Συσκευής.
                                • Κατάλληλο για χρήση σε κουζίνα (φωτιές τύπου F) παρουσία Ηλεκτρικού ρεύματος
                                • Το Δοχείο του Πυροσβεστήρα δεν είναι Υπό Πίεση.
                                • Δεν χρειάζεται Ετήσια Συντήρηση.
                                • Δεν Αφήνει Κατάλοιπα μετά την Κατάσβεση.
                                • Φιλικό προς το Περιβάλλον – Εύκολο στην Χρήση.
                                • Ασφαλές – Αξιόπιστο Προϊόν – Πιστοποιημένο κατά CE.
                            Ο παγκοσμίως Μικρότερος Πιστοποιημένος Φορητός Πυροσβεστήρας αποτελεί την πλέον ενδεδειγμένη και αξιόπιστη λύση για κατάσβεση πυρκαγιών σε κατοικίες, σκάφη, επαγγελματικούς χώρους, κτλ.`,
                            photo: `assets/images/products/7Gadjets/3.jpg`,
                        }
                    ],
                    active: 1
                },
            ],
            active: 1,
            color: 'white'
        },
    ]
};

