import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

const navRoutes = [
    { title_gr: '', url: '', active: true, ariaLabel: 'Αρχική σελίδα, home page', class: 'logo' },
    { title_gr: 'Προϊόντα', url: 'products', active: false,
        ariaLabel: 'Κατάλλογος προϊόντων, πυροσβεστήρες, πυροσβεστικά υλικά', class: 'products-icon' },
    { title_gr: 'Πληροφορίες', url: 'faq', active: false,
        ariaLabel: 'Πληροφορίες σχετικά με την εταιρία μας, συχνές ερωτήσεις', class: 'faq-icon' },
    { title_gr: 'Βίντεο', url: 'gallery', active: false,
        ariaLabel: 'Κατάλλογος βίντεο απο σεμινάρια και ασκήσεις πυρόσβεσης', class: 'gallery-icon' },
    { title_gr: 'Επικοινωνία', url: 'contact', active: false,
        ariaLabel: 'Επικοινωνία με τηλέφωνο, email και χάρτης για να μας επισκεφτείτε', class: 'contact-icon' }
];

@Component({
    selector: 'header',
    templateUrl: 'header.component.html'
})

export class HeaderComponent implements OnInit, AfterViewInit {
    isActive: boolean;
    routes: any = navRoutes;
    menuOpen: boolean;
    constructor(public router: Router, private route: ActivatedRoute) {

    }
    ngOnInit() {
        this.menuOpen = false;
    }
    ngAfterViewInit() {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(URLevent => {
            const path = URLevent['url'].replace('/', '');
            this.toggleFocus(path);
        });
    }
    toggleFocus(url) {
        this.routes.forEach((route, i) => {
            route.active = false;
            route.active = route.url === url ? true : false;
        });
    }
    toggleMenu(event) {
        this.menuOpen = !this.menuOpen;
    }
}
