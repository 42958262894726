import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[loaded]',
})

export class LoadedDirective {
    el;
    constructor(el: ElementRef) {
        this.el = el;
        this.el.nativeElement.addEventListener('load', (e) => this.getLoadedState(e));
    }
    @HostListener('mouseenter') onMouseEnter(e) {
        this.getLoadedState(e);
    }

    getLoadedState(el) {
    }
}
