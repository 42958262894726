import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { youtubeAPICredentials } from './token';

@Injectable()
export class GetYtContentService {
    params: string = youtubeAPICredentials.params;
    constructor(private http: HttpClient) {
    }

    getChannelFeed() {
        this.params = this.params
            .replace('{CHANNEL_ID}', youtubeAPICredentials.channelId)
            .replace('{NUM_RESULTS}', youtubeAPICredentials.maxResults.toString() || '')
            .replace('{API_KEY}', youtubeAPICredentials.APIkey);
        const URL = youtubeAPICredentials.urlBase + this.params;
        return this.http.get(URL);
    }
}
