
import { trigger, state, style, animate, transition, stagger, query } from '@angular/animations';
import { RandomEffect } from './randomEffect';

export let slide = trigger('slide', [
    state('active', style({
        left: '0px', opacity: 1, filter: 'blur(0)',
        transform: `translateX(-${Math.floor(Math.random() * 0)}px) translateY(-0px) scale(1)`,
        transformOrigin: '50% 50%'
    })),
    state('inactive', style({
        left: '0px', opacity: 0, filter: `blur(${Math.floor(Math.random() * 50)}px)`,
        transform: `translateX(-${Math.floor(Math.random() * 0)}px) translateY(${Math.floor(Math.random() * 0)}px) scale(1.2)`,
        transformOrigin: '50% 50%'
    })),
    transition('active => inactive', animate('550ms ease-in-out')),
    transition('inactive => active', animate('1800ms ease-out')),
    transition('void => *', animate('2200ms ease-out')),
]);

export let hover = trigger('hover', [
    state('active', style({ backgroundColor: 'rgba(0,0,0,0)' })),
    state('inactive', style({ backgroundColor: 'rgba(0,0,0,0.5)' })),
    transition('active <=> inactive', animate('300ms ease-in-out'))
]);

export let scale = trigger('scale', [
    state('1', style({ transform: 'scale(1)', display: 'block', opacity: 1 })),
    state('void', style({ transform: 'scale(0.75)', opacity: 0, display: 'none' })),
    transition('1 => void', animate('300ms ease-in')),
    transition('void => 1', animate('300ms ease-out')),
]);

export let fadeIn = trigger('fadeIn', [
    transition('* => active', [
        query('li', [
            style({ opacity: 0, transform: 'translate(150px, 100px) rotate(-4deg)'}), // Change to dynamic different effect
            stagger(200, [
                animate('0.35s ease-out', style({ opacity: 1, transform: 'translate(0px)', top: '0px' }))
            ])
        ]),
    ])
]);
