import { Component } from '@angular/core';
// declare google analytics
declare const ga: any;

@Component({
    selector: 'homepage',
    templateUrl: 'homepage.component.html'
})

export class HomepageComponent {

}
